/* Try to keep custom styles to a minimum. */
/* Be aware that these styles will effect your entire application. */
/* Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname */

.praxcss-height100vh {
  height: 100vh;
}

.myClass-Horizontal .C-FormRadio {
  display: flex;
}

.myClass-Horizontal .C-FormRadio__Option {
  margin-right: 24px;
}

.inputtype input[type='number']::-webkit-inner-spin-button,
.inputtype input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.C-DatePicker__MonthIcons {
  right: 3px;
  top: 22px
}
.C-Input--type-info.isErrored {
  height: auto;
  margin-bottom: 15px;
}
